.checkNumber {
  &__container {
    padding: 0 20px;
  }

  &__row {
    display: flex;
    justify-content: center;
    & + & {
      margin-top: 18px;
    }
  }

  &__text {
    font-size: 22px;
    text-align: center;

    & span {
      font-size: 1.25em;
      white-space: nowrap;
    }
  }

  &__number {
    font-weight: 700;
  }

  &__button {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
    & + & {
      margin-left: 15px;
    }
    &.--return {
      filter: grayscale(1);
    }
  }
}