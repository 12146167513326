*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: $roboto;
	font-size: 15px;
	line-height: 1.5;
	color: $black;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-focus-ring-color: rgba(255, 255, 255, 0);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

ul, ol {
	margin: 0;
	padding: 0;

	li {
		list-style: none;
	}
}

address {
	font-style: normal;
}

img,
video {
	max-width: 100%;
}

img {
	border: 0;
	vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

p {
	font-family: $roboto;
	margin: 0;
	& + p {
		margin-top: 30px;
	}
}

a {
	color: inherit;
	text-decoration: none;
}

textarea,
input {
	border: none;
	&:focus {
		outline: none;
	}
}

button {
	outline: none;
	border: none;
	color: $white;
	font-family: $roboto;
	font-size: 16px;
	line-height: 1;
	background: none;
	transition: transform .3s;
	cursor: pointer;
	&:active {
		transform: translate(2px, 2px);
	}
}

.wrapper {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	min-height: 100vh;
	overflow: hidden;
}

.main {

}

.container {
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
	@media (max-width: 730px) {
		padding: 0 15px;
	}
}

.none {
	display: none;
}

.title {
	font-family: $roboto;
	font-size: 36px;
	line-height: normal;
}