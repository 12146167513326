.input {
    padding: 17px 0px 17px 23px;
    position: relative;
    display: block;
    border-radius: 4px;
    border: 1px solid $border;
    background-color: $white;
    transition: border-color .3s;
    cursor: text;
    &:hover {
        border-color: $active;
    }
    &.--textarea {
        min-height: 160px;
        height: 100%;
        & .input__element {
            height: 100%;
            font-family: $roboto;
            font-size: 16px;
        }
    }
    &__file {
        position: relative;
        display: block;
        width: 100%;
        cursor: pointer;
        @media(min-width: 1600px) {
            margin-left: 20px;
        }
        & input {
            position: absolute;
            width: 0;
            height: 0;
            opacity: 0;
        }
        &-el {
            display: inline-block;
            vertical-align: middle;
            & + & {
                margin-left: 5px;
            }
        }
        &-icon {
            width: 20px;
            height: 20px;
            background: url('./images/icon-clip.svg') 50% 50% no-repeat;
        }
        &-text {
            line-height: 1.2;
        }
        &-count {
            display: block;
            margin-top: 10px;
            width: 100%;
            color: $grey;
            font-size: 13px;
            transition: color .3s;
            &.--error {
                color: $error;
            }
        }
        &-start {
            display: flex;
            align-items: center;
        }
        &-end {

        }
    }
    &.--hasError {
        border-color: $error !important;
        & .input__error-msg {
            opacity: 1;
        }
        & .input__placeholder {
            opacity: 0;
        }
    }
    &.--focused, &.--hasValue {
        border-color: $active;
        & .input__element {
            opacity: 1;
        }
        & .input__error-msg, & .input__placeholder {
            transform: translateY(-15px);
            font-size: 12px;
        }
    }
    &__element {
        word-wrap: break-word;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0;
        color: #333;

        width: 100%;
        resize: none;
        opacity: 0;
        transition: opacity .3s;
        &:focus {
            opacity: 1;
        }

        &::selection {
            background: #7f859836;
        }
    }
    &__placeholder, &__error-msg {
        top: 16px;
        font-size: 15px;

        position: absolute;
        // top: 19px;
        left: 24px;
        will-change: transform;
        transition: font-size .3s,transform .3s,opacity .3s;
    }
    &__placeholder {
        color: $formPlaceholder;
    }
    &__error-msg {
        opacity: 0;
        color: $error;
    }
}

@media (max-width:1200px) {
    .input {
        &.--textarea {
            min-height: 120px;
        }
    }
}
@media (max-width:767px) {
    .input {
        padding-top: 9px;
        padding-bottom: 9px;
        &__error-msg, &__placeholder {
            top: 12px;
            font-size: 12px;
        }
        &__file {
            &-text {
                font-size: 14px;
            }
            &-count {
                bottom: -1px;
            }
        }
        &.--focused, &.--hasValue {
            & .input__error-msg, & .input__placeholder {
                opacity: 0;
                transform: none;
            }
        }
    }
}
@media (max-width:450px) {
    .input {
        &.--textarea {
            min-height: 100px;
        }
    }
}