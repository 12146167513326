.form {
    position: relative;
    &.none {
        display: none;
    }
    &.--thanks {
        & form {
            opacity: 0;
        }
        & .form__thanks {
            visibility: visible;
        }
    }
    &.--check {
        & .form__checkNumber {
            opacity: 1;
            transition: opacity .3s;
            z-index: 1;
        }
    }
    &.--slim {
        min-height: unset;
        padding: 90px 0;
        & .container {
            width: 100%;
            max-width: 440px;
            min-width: unset;
        }
        & .form {
            &__img {
                display: none;
            }
            &__title {
                margin-bottom: 50px;
            }
            &__row {
                flex-direction: column;
                & + .form__row {
                    margin-top: 30px;
                }
            }
            &__block {
                width: 100%;
                & + .form__block {
                    margin-top: 30px;
                }
            }
        }
    }
    & .link {
        font-size: 13px;
        color: $white;
        border-color: $white;
    }
    &__img {
        position: absolute;
        top: 50%;
        left: 100px;
        transform: translateY(-50%);
    }
    &__container {
        position: relative;
        margin: 0 auto;
        z-index: 1;
    }
    &__checkNumber {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $formBg;
        opacity: 0;
    }
    &__title {
        position: relative;
        margin-bottom: 80px;
        font-size: 36px;
        text-align: center;
        line-height: 1.2;
        & span {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -20px;
            font-family: $roboto;
            font-size: 13px;
            color: $grey;
        }
    }
    &__row {
        display: flex;
        justify-content: space-between;
        & + & {
            margin-top: 40px;
        }
    }
    &__block {
        width: calc(100% / 2 - 10px);
        &.--links {
            display: flex;
        }
        &-license {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            @media(max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
                margin: 0 auto;
            }
        }
    }
    &__file {
        margin-top: 10px;
    }
    &__textarea {
        max-height: 100%;
        height: 160px;
        margin: 20px 0px 20px;
    }
    &__input {
        & + & {
            margin-top: 20px;
        }
    }
    &__button {
        width: 100%;
        max-width: 290px;
        padding: 16px 30px 16px;
        font-size: 16px;
        font-weight: 200;
        font-weight: 400;
        border-radius: 4px;
        background-image: linear-gradient(to top,#f28525 0%,#e97c20 100%);
        transition: filter .3s;
        @media (max-width: 767px) {
            padding: 16px 30px 15px;
            font-size: 17px;
        }
        &[disabled] {
            filter: grayscale(1);
            pointer-events: none;
        }
        &:not([disabled]):hover {
            background-image: linear-gradient(to bottom,#f28525 0%,#e97c20  100%);
        }
    }
    &__thanks {
        visibility: hidden;
        width: 100%;
        height: auto;
        top: 0;
        left: 0;
        z-index: 50;
        position: absolute;
        &-title {
            margin-bottom: 30px;
            font-size: 36px;
            text-align: center;
            line-height: 1.2;
        }
        &-description {
            z-index: 50;
            max-width: 400px;
            margin: 0 auto;
            font-size: 19px;
            text-align: center;
            & a {
                display: block;
                margin: 10px 0;
                font-size: 1.8em;
                font-weight: 700;
                transition: color .3s;
                &:hover {
                    color: $active;
                }
            }
        }
    }
}
.--submit {
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
}

@media (max-width:1200px) {
    .form {
        padding: 0;
        background-color: transparent;
        &.--slim {
            padding: 0;
            & .container {
                max-width: unset;
                margin: 0;
                // padding: 90px 0;
            }
            & .form__container {
                max-width: 420px;
            }
        }
        & .container {
            width: unset;
            // margin: 0 40px;
            // padding-top: 95px;
            // padding-bottom: 135px;
            border-radius: 4px;
        }
    }
}
@media (max-width: 570px) {
    .form {
        &__container {
            max-width: 420px;
        }
        &:not(.--slim) {
            & .form__title {
                width: 500px;
                margin-left: -40px;
                margin-bottom: 40px;
            }
        }
        &__row {
            flex-direction: column;
            & + & {
                margin-top: 30px;
            }
        }
        &__block {
            width: 100%;
            & + & {
                margin-top: 20px;
            }
            &.--submit {
                margin-top: 30px;
                text-align: center;
            }
        }
    }
}
@media (max-width:767px) {
    .form {
        &.--slim {
            & .container {
                width: auto;
                margin: 0px;
                padding-left: 10px;
                padding-right: 10px;
            }
            & .form__submit {
                font-size: 14px;
            }
        }
        &:not(.--slim) {
            & .form__title {
                width: auto;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 30px;
            }
        }
        &__title {
            font-size: 22px;
            & span {
                bottom: -18px;
                font-size: 12px;
            }
        }
        &__thanks {
            &-title {
                font-size: 29px;
            }
            &-description {
                font-size: 16px;
                & a {
                    margin: 15px 0;
                }
            }
        }
    }
}
@media (max-width:375px) {
    .form {
        &.--slim {
            & .container {
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }
        & .container {
            margin: 0;
            border-radius: 0;
        }
    }
}